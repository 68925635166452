import dayjs from 'dayjs';

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const formatDateTime = (time, date) => {

    // Use a default date when parsing time to avoid invalid parsing
    const defaultDate = '1970-01-01'; // Arbitrary default date
    const timeWithDate = `${defaultDate}T${time}`;

    const formattedTime = dayjs(timeWithDate, 'YYYY-MM-DDTHH:mm:ss').isValid()
        ? dayjs(timeWithDate, 'YYYY-MM-DDTHH:mm:ss').format('hh:mm A')
        : 'Invalid Time';

    const formattedDate = dayjs(date, 'YYYY-MM-DD').isValid()
        ? dayjs(date, 'YYYY-MM-DD').format('DD MMM YYYY')
        : 'Invalid Date';


    return { formattedTime, formattedDate };
};