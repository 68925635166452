import React from 'react';
import { Drawer, Grid, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            color: 'rgba(31, 31, 42, 1)',
        }
    },
    drawerHeader: {
        height: '56px',
        padding: '16px',
    },
    listItem: {
        '&.MuiListItem-root': {
            padding: '16px',
            height: '48px',
            '& .MuiTypography-root': {
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24.8px',
                color: 'rgba(31, 31, 42, 1)',
            },
        },
    },
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
    },
});

const ReusableDrawer = ({ open, onClose, title, menuItems }) => {
    const classes = useStyles();

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            classes={{ paper: classes.drawerPaper }}
        >
            <Grid
                className={classes.drawerHeader}
                container
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <List>
                {menuItems.map((item, index) => (
                    <ListItem key={index} className={classes.listItem}>
                        <ListItemButton
                            disabled={item.disable}
                            onClick={item.action}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default ReusableDrawer;
