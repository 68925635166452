import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import ParentSelector from '../Buttons/ParentSelector';
import usePetParentStorage from '../../managers/parentManager';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
    detailSection: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0px 0px 12px 0px',
        gap: '8px',
    },
    iconText: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.94px',
        }
    },
    infoSection: {
        marginBottom: '16px',
    }
}));


const InfoSection = ({ title, value }) => {

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        toast.success('Copied to clipboard');
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '4px'
            }}>
                <Typography align="left" sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    lineHeight: '18.6px',
                    color: 'var(--Dark-Grey, #7D7D97)'
                }}>
                    {title}
                </Typography>
                <Typography align="left" sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24.8px',
                    textAlign: 'left',
                    color: '#1F1F2A'
                }}>
                    {value}
                </Typography>
            </div>
            {/* <div>
                <button onClick={() => copyToClipboard(value)} style={{
                    background: 'white',
                    height: 40,
                    width: 40,
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: '0px 2px 7.1px 0px #00000026',
                    borderRadius: 8,
                }}>
                    <ContentCopyRoundedIcon />
                </button>
            </div> */}
        </div>
    );
};


const PetParentInfo = ({ parent }) => {
    const classes = useStyles();

    return (
        <>
            <div>
                {parent.name && (
                    <div className={classes.infoSection}>
                        <InfoSection title="Name" value={parent.name} />
                    </div>
                )}
                {parent.email && (
                    <div className={classes.infoSection}>
                        <InfoSection title="Email" value={parent.email} />
                    </div>
                )}
                {parent.phone && (
                    <div className={classes.infoSection}>
                        <InfoSection title="Phone" value={parent.phone} />
                    </div>
                )}
            </div>
        </>
    );
};

const PetParentsDetails = ({ pet, isSharing, profileType }) => {
    const classes = useStyles();
    const [currentParent, setCurrentParent] = useState(null);
    const {
        storedPetParents,
        getCurrentPetParent,
        fetchPetParents,
    } = usePetParentStorage('customer');

    useEffect(() => {
        (async () => {
            if (pet) {
                const parent = await fetchPetParents(pet.customer_id, isSharing);
                setCurrentParent(() => parent); 
            }
        })();
    }, [pet, isSharing]);

    const handleCopyAllInfo = () => {
        if (currentParent) {
            const parentInfo = `Name: ${currentParent.name || ''}\nEmail: ${currentParent.email || ''}\nPhone: ${currentParent.phone || ''}`;
            navigator.clipboard.writeText(parentInfo);
            toast.success('Copied to clipboard');
        }
    }


    return (
        <>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Parents
                    </Typography>
                    <div>
                        {(!isSharing || profileType === 'full') &&

                            <button onClick={handleCopyAllInfo} style={{
                                background: 'white',
                                height: 40,
                                width: 100,
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: 8,
                                gap: '6px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',

                                color: '#7019FF',
                                padding: '0px',

                            }}>
                                <ContentCopyRoundedIcon
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                /> <span
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '21.7px',
                                        textAlign: 'center',
                                        color: '#7019FF'
                                    }}
                                >Copy all</span>
                            </button>
                        }
                    </div>
                </div>
                <ParentSelector selectedParent={currentParent} onSelect={() => { /* handle parent selection */ }} disabled={true} />

            </div>
            {(!isSharing || profileType === 'full') && currentParent && <PetParentInfo parent={currentParent} />}
        </>
    );
};

export default PetParentsDetails;