import { useState } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';

const api = new ApiClient();

const usePetParentStorage = (key, initialData = null) => {
    const [storedPetParents, setStoredPetParents] = useState(() => {
        try {
            // Get pet parent profiles from local storage
            const item = window.localStorage.getItem(key);
            const storedItems = item ? JSON.parse(item) : initialData;
            window.localStorage.setItem(key, JSON.stringify(storedItems)); // Initialize if no data
            return storedItems;
        } catch (error) {
            console.error("Error reading pet parent profiles from localStorage", error);
            return initialData;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedPetParents) : value;
            // Update state and localStorage
            setStoredPetParents(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error saving pet parent profiles to localStorage", error);
        }
    };

    // CRUD Operations for Pet Parent Profiles
    const syncLocalStorage = (parent) => {
        try {
            localStorage.setItem('customer', JSON.stringify(parent));
        } catch (error) {
            console.error("Error syncing pets to localStorage:", error);
        }
    };
    // Update a pet parent profile
    const updatePetParent = (parentId, newParentData) => {
        setValue((prevParents) => ({
            ...newParentData }
        ));
    };

    const updateParentByIdAPI = async (parentId, newParentData) => {
        try {
            const response = await api.put(`cms/customer/update/${parentId}`, newParentData);

            if (response.error) {
                console.error("Error updating parent:", response.error);
                toast.error("Error updating parent");
                return;
            }

            setValue((prevParents) => ({
                ...newParentData }
            ));

            syncLocalStorage(newParentData); // Sync with localStorage

            toast.success("Parent updated successfully");
        } catch (error) {
            console.error("Error updating pet:", error);
            toast.error("Error updating pet");
        }
    };

    // Add a new pet parent profile
    const addPetParent = (newParent) => {
        setValue((prevParents) => ({
            ...newParent
        }));
        return newParent.id;
    };

    // Delete a pet parent profile
    const deletePetParent = (parentId) => {
        setValue((prevParents) => {
            const { ...remainingParents } = prevParents; // Remove parent with parentId
            return remainingParents;
        });
    };


    // Fetch pet parent profiles from API by customer_id
    const fetchPetParents = async (customerId, isSharing=false) => {
        try {
            const response = await api.get(`cms/customer/retrieve?customer_id=${customerId}`);

            if (response.error) {
                console.error("Error fetching pet parents:", response.error);
                toast.error("Error fetching pet parents");
                return;
            }

            if (!isSharing) {
                setValue((prevParents) => ({
                    ...response
                }));
                syncLocalStorage(response); // Sync with localStorage
            }
            return response;
            // toast.success("Pet parents fetched successfully");
        } catch (error) {
            console.error("Error fetching pet parents:", error);
            toast.error("Error fetching pet parents");
        }
    };

    // Get a pet parent profile by ID
    const getPetParentById = (parentId) => {
        return storedPetParents[parentId] || null;
    };

    // Get all pet parents
    const getAllPetParents = () => {
        return Object.values(storedPetParents);
    };

    const getCurrentPetParent = () => {
        // Get the current pet parent profile
        const currentPetParentCustomerData = window.localStorage.getItem('customer');
        if (!currentPetParentCustomerData) {
            return null;
        }
        const currentPetParentAuthData = window.localStorage.getItem('auth');
        // parse the string to object
        const currentPetParentObject = JSON.parse(currentPetParentCustomerData);
        const currentPetParentAuthObject = JSON.parse(currentPetParentAuthData);

        const currentPetParentData = {
            ...currentPetParentObject,
            email: currentPetParentAuthObject.data.email ?? '',
        };

        return currentPetParentData ?? null;
    }

    return {
        storedPetParents,
        updatePetParent,
        addPetParent,
        deletePetParent,
        getPetParentById,
        getAllPetParents,
        getCurrentPetParent,
        updateParentByIdAPI,
        fetchPetParents,
    };
};

export default usePetParentStorage;
