import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import PetsHeader from '../components/Header/petsHeader.jsx';
import usePetStorage from '../managers/petsManager.jsx';
import { paths } from '../data/constants.js';
import PetList from '../components/PetsList/PetList.jsx';
import UpcomingList from '../components/UpcomingList/UpcomingList.jsx';
import AboutMeEditForm from '../components/About/AboutMeEdit.jsx';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        // borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));


const PetHome = () => {
    // Styles
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { isNewPetOwner, goToTab } = location.state || {}; // Accessing state safely
    const clearNewParentState = () => {
        navigate(paths.pets, { state: null, replace: true });
    };

    // States
    const {
        pets,
        updatePet,
        deletePet,
    } = usePetStorage();
    const [reminderCount, setReminderCount] = React.useState(0);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [filteredPets, setFilteredPets] = React.useState(pets);
    const [searchTerm, setSearchTerm] = React.useState('');
    // Functions
    const handlePetDelete = (petId) => {
        deletePet(petId);
    };

    const handlePetNameUpdate = (petId, newName) => {
        updatePet(petId, { name: newName });
    };
    
    const tabMap = {
        pets: 0,
        upcoming: 1,
        about: 2,
    };

    useEffect(() => {
        if (goToTab) {
            setTabIndex(tabMap[goToTab]);
        }
    }, [goToTab]);

    useEffect(() => {
        if (pets) {
            setFilteredPets(pets);
        }
    }, [pets]);

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (searchTerm === '') {
            setFilteredPets(pets);
            return;
        }
        const filtered = Object.values(pets).filter((pet) => {
            return pet.name.toLowerCase().includes(searchTerm.toLowerCase());
        }).reduce((acc, pet) => {
            acc[pet.id] = pet;
            return acc;
        }, {});
        setFilteredPets(filtered);
    }

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <PetsHeader handleSearch={handleSearch} tabIndex={tabIndex} setTabIndex={setTabIndex} reminderCount={reminderCount}/>
            </Header>
            { filteredPets && (<Container maxWidth="lg">
                <div className={classes.contentContainer}>
                    {tabIndex === 0 ? (
                        <PetList 
                            searchTerm={searchTerm}
                            pets={filteredPets}
                            handlePetDelete={handlePetDelete}
                            handlePetUpdate={handlePetNameUpdate}
                            isNewPetOwner={isNewPetOwner}
                            clearNewParentState={clearNewParentState}
                        />
                    ) : tabIndex === 1 ? (
                        <UpcomingList pets={pets} setReminderCount={setReminderCount}/>
                    ) : tabIndex === 2 ? (
                        // coming soon in the middle
                        <AboutMeEditForm  />
                    ) : setTabIndex(0)}
                </div>
            </Container> )}
        </>
    );
};

export default PetHome;
