// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#7019FF',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#7019FF',
        },
        background: {
            notes: 'rgba(246, 247, 251, 1)', // Light Grey background color
            pets: 'rgba(255, 255, 255, 1)', // White background color
            default: 'rgba(255, 255, 255, 1)', // White background color
        },
        text: {
            primary: 'rgba(31, 31, 42, 1)', // Custom text color
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        color: '#1F1F2A',
        textTransform: 'none',
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
        },
        body1: {
            fontSize: '1rem',
        },
        button: {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px', // Customize the border radius
                },
            },
        },
    }
});

export default theme;
