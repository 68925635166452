import React from 'react';
import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PetProfileDivider from '../../Dividers/PetProfileDivider';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '12px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        // padding: '16px',
        width: '100%',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '24px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    chipLabel: {
        "&.MuiChip-label": {
            padding: '8px',
        },
    },
    breedGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
    },
}));

const PetPersonalityForm = ({ formik }) => {
    const classes = useStyles();
    
    return (
        <div style={{ width: "100%" }}>

            <div className={classes.detailSectionTitle}>
                <Typography align="left" className={classes.detailTitle}>
                    Personality and preferences
                </Typography>
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/psychology.svg`} alt="brain" />
                    <Typography align="left" className={classes.iconText}>
                        Personality
                    </Typography>
                </div>
                <TextField
                    multiline
                    name="personality"
                    value={formik.values?.extra_data?.personality}
                    onChange={
                        (e) => formik.setFieldValue("extra_data.personality", e.target.value)
                    }
                    className={classes.inputTextBox}
                    placeholder='e.g. Expressive and vocal (only when she wants something)'
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/favFood.svg`} alt="food" />
                    <Typography align="left" className={classes.iconText}>
                        Favourite food
                    </Typography>
                </div>
                <TextField
                    multiline
                    name="favorite_food"
                    value={formik.values?.extra_data?.favorite_food}
                    onChange={
                        (e) => formik.setFieldValue("extra_data.favorite_food", e.target.value)
                    }
                    className={classes.inputTextBox}
                    placeholder='e.g. Tuna'
                />
            </div >
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/landscape.svg`} alt="activity" />
                    <Typography align="left" className={classes.iconText}>
                        Favourite activity
                    </Typography>
                </div>
                <TextField
                    multiline
                    name="favorite_activity"
                    value={formik.values?.extra_data?.favorite_activity}
                    onChange={
                        (e) => formik.setFieldValue("extra_data.favorite_activity", e.target.value)
                    }
                    className={classes.inputTextBox}
                    placeholder='e.g. Playing with a ball'
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <img src={`${process.env.PUBLIC_URL}/profileIcons/fear.svg`} alt="fear" />
                    <Typography align="left" className={classes.iconText}>
                        Biggest fear
                    </Typography>
                </div>
                <TextField
                    multiline
                    name="biggest_fear"
                    value={formik.values?.extra_data?.biggest_fear}
                    onChange={
                        (e) => formik.setFieldValue("extra_data.biggest_fear", e.target.value)
                    }
                    className={classes.inputTextBox}
                    placeholder='e.g. Vacuum cleaner'
                />
            </div>
            <PetProfileDivider />
        </div>
    );
};

export default PetPersonalityForm;