import React, { useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import usePetParentStorage from '../../managers/parentManager';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    backButton: {
        '&.MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                marginRight: 'auto',
            }
        }
    },
    tabsContainer: {
        flexGrow: 1,
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tabTitle: {
        '&.MuiTab-root': {
            fontSize: '14px',
            lineHeight: '17.07px',
            color: "#7D7D97",
            fontWeight: 500,
            textTransform: 'none',
        }
    },
    activeTab: {
        '&.MuiTab-root': {
            fontWeight: 600,
            color: "#1F1F2A"
        }
    },
    indicator: {
        backgroundColor: "#1F1F2A",
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        boxShadow: '0px 2px 12px 0px #00000005',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 48,
        padding: "12px 16px"
    },
    logo: {
        width: '60px',
    },
}));

const HomeHeader = ({ navigate, classes, isLoggedIn, handleLogOut, currentCustomer }) => {
    const [customerAvatar, setCustomerAvatar] = React.useState(null);

    useEffect(() => {
        if (currentCustomer) {
            setCustomerAvatar(currentCustomer.photo || null);
        }
    }, [currentCustomer]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuItems = [
        {
            label: 'Log out',
            icon: <LogoutIcon />,
            action: handleLogOut,
        },
    ];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuAction = (action) => {
        action();
        handleCloseMenu(); // Close menu after action
    };

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget); // Anchor menu to the clicked element
    };

    return (
        <div className={classes.logoContainer} style={{ cursor: 'pointer' }} onClick={() => navigate(paths.pets)}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt="logo" />
            {isLoggedIn && (
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item >
                        {/* User icon */}
                        <img
                            src={customerAvatar || `${process.env.PUBLIC_URL}/logo-gray.png`}
                            alt="User"
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: '50%',
                                cursor: 'pointer',
                                border: '1px solid #7019FF',
                                objectFit: 'cover',
                            }}
                            onClick={handleProfileClick}
                        />
                    </Grid>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                style={{
                                    color: '#1F1F2A',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                    gap: '8px',
                                }}
                                disabled={item.disable}
                                onClick={() => handleMenuAction(item.action)}
                            >
                                {item.icon}
                                <span style={{
                                    color: '#1F1F2A',
                                }}>
                                    {item.label}
                                </span>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            )}
        </div>
    );
};

const PetsHeader = ({ setTabIndex, tabIndex, reminderCount, handleSearch }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    const { storedPetParents } = usePetParentStorage('customer');
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if (!auth) {
            window.location.href = '/login';
        }

        const authJSON = JSON.parse(auth || '{}');
        if (!authJSON.state || authJSON.state !== 'logged-in') {
            window.location.href = '/login';
        }
        setIsLoggedIn(true);

    }, []);

    const handleLogOut = () => {
        localStorage.clear();
        window.location.href = '/login';
    }

    return (
        <div className={classes.petHeaderContainer}>
            <HomeHeader classes={classes} navigate={navigate} currentCustomer={storedPetParents} isLoggedIn={isLoggedIn} handleLogOut={handleLogOut} />
            <div className={classes.tabsContainer}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="#1F1F2A"
                    TabIndicatorProps={{ style: { background: "#1F1F2A" } }}
                    variant="fullWidth"
                >
                    <Tab className={`${classes.tabTitle} ${tabIndex === 0 ? classes.activeTab : ''}`} label="Pets" />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 1 ? classes.activeTab : ''}`} label={
                        <div className={classes.tabLabelContainer}>
                            Reminders &nbsp;
                            {reminderCount > 0 && (
                                <span style={{
                                    color: '#7019FF'
                                }}>
                                    {reminderCount}
                                </span>
                            )}
                        </div>
                    } />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 2 ? classes.activeTab : ''}`} label="Parent" />
                </Tabs>
            </div>
            {tabIndex === 0 && (
                <div className={classes.tabsContainer}>
                    {/* Search bar with magnification icon */}
                    <TextField
                        fullWidth
                        placeholder="Search a pet"
                        variant="outlined"
                        onChange={(e) => handleSearch(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '30px',
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                                '& input': {
                                    padding: 0,
                                    color: '#1F1F2A',
                                    fontSize: '16px',
                                    lineHeight: '21.7px',
                                    fontWeight: 500,

                                },
                            },
                            margin: '13px 20px',
                            width: 'calc(100% - 32px)',
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" sx={{ width: '16.88px', height: '16.88px' }}>
                                    <SearchIcon sx={{ color: '#1C1B1F' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default PetsHeader;
