import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Typography, Box, IconButton, Grid, ToggleButtonGroup, ToggleButton, Avatar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { paths, shareProfileTypes } from '../../data/constants';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            color: 'rgba(31, 31, 42, 1)',
        }
    },
    subTitle: {
        '&.MuiTypography-root': {
            fontSize: '12px',
            fontWeight: 600,
            textTransform: 'uppercase',
            lineHeight: '18.6px',
            color: '#7D7D97',
        }
    },
    drawerHeader: {
        alignItems: 'center',
        padding: '16px',
    },
    toggleButtonText: {
        '&.MuiToggleButton-root': {
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'none',
            padding: '12px',
            color: '#1F1F2A',
        },
    },
    enabledIconContainer: {
        color: '#1F1F2A',
    },
    disabledIconContainer: {
        color: '#F6F7FB',
    },
}));

const mapSelectionToType = (selection) => {
    switch (selection) {
        case 'friends':
            return 'basic';
        case 'vets':
            return 'full';
        default:
            return 'full';
    }
};

const ShareProfileTypes = ({ type }) => {

    const classes = useStyles();



    const sections = [
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/dogHead.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/dogHead-gray.svg`,
            text: 'Basic info',
            type: ['full', 'basic'],
        },
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/diary.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/diary-gray.svg`,
            text: 'Diary entries',
            type: ['full', 'basic'],
        },
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/psychology.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/psychology-gray.svg`,
            text: 'Personality',
            type: ['basic'],
        },
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/fingerprint.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/fingerprint-gray.svg`,
            text: 'Pet ID',
            type: ['full'],
        },
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/social.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/social-gray.svg`,
            text: 'Social links',
            type: ['basic'],
        },
        {
            activeIcon: `${process.env.PUBLIC_URL}/profileIcons/contact.svg`,
            inactiveIcon: `${process.env.PUBLIC_URL}/profileIcons/contact-gray.svg`,
            text: 'Contact info',
            type: ['full'],
        }
    ];

    // Table 6x2 with 12 icon typography
    return (
        <Grid container spacing={2}>
            {sections.map((item, index) => (
                <Grid item xs={6} key={index}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'start'}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginRight: 12 }}>
                            <img src={item.type.includes(mapSelectionToType(type)) ? item.activeIcon : item.inactiveIcon || ''} alt={item.text}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    // svg fill color not possible with img tag
                                }}
                            />
                        </div>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21.7px',
                                textAlign: 'center',
                                color: item.type.includes(mapSelectionToType(type)) ? '#1F1F2A' : '#D9D9D9',
                            }}
                        >{item.text}</Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>

    );
};


const ShareProfileDrawer = ({ open, onClose, pet }) => {
    const classes = useStyles();
    const [shareWith, setShareWith] = useState('friends');
    const [subTitle, setSubTitle] = useState(shareProfileTypes.FRIENDS);
    const handleShareChange = (event, newValue) => {
        if (newValue !== null) {
            setShareWith(newValue);
            const currentProfile = mapSelectionToType(newValue);
            setSubTitle(currentProfile);
        }
    };

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(`${window.location.origin}${paths.petShareProfile(pet.id)}?type=${subTitle.toLowerCase()}`);
            toast.success('Copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy:', err);
            toast.error('Failed to copy to clipboard');
        }finally {
            onClose();
        }
    };

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            sx={{
                zIndex: 10002,
                '& .MuiDrawer-paper': {
                    height: '376px',
                    borderRadius: '16px 16px 0px 0px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px -4px 8px 0px #1E07511F',
                    maxWidth: '600px',
                    margin: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                },
            }}
        >
            <Grid className={classes.drawerHeader} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.title}>Share With</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Box sx={{ padding: '0px 20px 16px 20px' }}>
                <ToggleButtonGroup
                    value={shareWith}
                    exclusive
                    onChange={handleShareChange}
                    aria-label="share with"
                    sx={{
                        width: '100%',
                        // gap: '8px',
                        backgroundColor: '#F6F6F6',
                        padding: '4px',
                        borderRadius: '8px',
                        '& .MuiToggleButtonGroup-grouped': {
                            border: 0,
                            flex: 1,
                            fontSize: '16px',
                            fontWeight: 500,
                            textTransform: 'none',
                            padding: '12px',
                            // transition to make the button slide
                            transition: 'all 0.3s',
                            '&.Mui-selected': {
                                backgroundColor: '#FFFFFF',
                                color: '#1F1F2A',
                                boxShadow: '0px 2px 8px 0px #1E07511F',
                                borderRadius: '8px !important',
                            },
                            // ':hover': {
                            //     backgroundColor: '#F6F6F6',
                            // },
                        },
                    }}
                >
                    <ToggleButton value="friends" className={classes.toggleButtonText}>Friends</ToggleButton>
                    <ToggleButton value="vets" className={classes.toggleButtonText}>Vets</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Grid sx={{
                padding: '0px 20px 16px 20px',
            }}>
                <Typography className={classes.subTitle}>{subTitle} PROFILE</Typography>
            </Grid>
            <Box sx={{ padding: '0px 20px 24px 20px' }}>
                <ShareProfileTypes type={shareWith} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    position: 'fixed',
                    bottom: 0,
                    maxWidth: '600px',
                    padding: '0px 20px 24px 20px',
                    width: '100%',
                }}
            >
                <Button
                    onClick={handleCopyLink}
                    variant="contained"
                    sx={{
                        width: '100%',
                        height: '48px',
                        fontSize: '16px',
                        lineHeight: '19.5px',
                        fontWeight: 600,
                        textTransform: 'none',
                    }}
                >
                    Copy profile link
                </Button>
            </Box>
        </Drawer>
    );
};

ShareProfileDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    pet: PropTypes.object.isRequired,
};

export default ShareProfileDrawer;