import React, { useState } from "react";
import { Button, Typography, Grid, TextField, MenuItem } from "@mui/material";
import { motion } from "framer-motion";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { paths } from "../data/constants";
import * as Yup from "yup";
import usePetStorage from "../managers/petsManager";
import { toast } from "react-toastify";
import ApiClient from '../utils/client';

const api = new ApiClient();

const useStyles = makeStyles((theme) => ({
    container: {
        "&.MuiGrid-root": {
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'flex-start',
            height: 'calc(100vh - 115px - 72px)', // 115px is the height of the header, 72px is the height of the bottom button
            background: 'linear-gradient(180deg, #E3E8FF 0%, #FFFFFF 39.37%)',
        },

        // paddingBottom: '70px',
    },
    form: {
        // center the form in the container
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        width: '100%',
        padding: '16px 20px',
        height: '100%',
    },
    input: {
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
        "&.MuiTextField-root": {
            borderRadius: "2px",
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield', // Firefox
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "0px 20px 24px 20px",
        background: "linear-gradient(#FFFFFF 39.37%, transparent)",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
        },
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 48,
        padding: "16px 16px"
    },
    logo: {
        width: '60px',
    },
    tabsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressBar: {
        height: 4,
        backgroundColor: "#7019FF",
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    stepTracker: {
        padding: '0px 20px 16px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

    },
    petCreationWrapper: {
        // Make it phone size only at 600px
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
            margin: 'auto',
        },
    },
    ageInputGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
    },
}));

const steps = [
    { stepValue: "name", title: "What’s your pet’s name?", placeholder: "e.g. Cookie" },
    { stepValue: "pet_type", title: "{name} is a..." },
    { stepValue: "age", title: "How old is {name}?", placeholder: "e.g. 6" },
    { stepValue: "parentEmail", title: "What’s your email address?", subTitle: "Create a Treet account or login to Treet", placeholder: "e.g. person@email.com" },
];

const HomeHeader = ({ navigate, classes }) => {
    return (
        <div onClick={() => navigate(paths.home)} className={classes.logoContainer} style={{ cursor: "pointer" }}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt='logo' />
        </div>
    );
};

// Yup validation schemas for each step
const validationSchemas = [
    Yup.object().shape({
        name: Yup.string()
            .required("")
            .min(2, "Pet name must be at least 2 characters"),
    }),
    Yup.object().shape({
        pet_type: Yup.string().required("Pet type is required"),
    }),
    Yup.object().shape({
        age: Yup.number()
            .required("Pet age is required")
            .min(0, "Age must be a positive number")
            .max(100, "Age must be less than 100"),
    }),
    Yup.object().shape({
        parentEmail: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
    }),
];

const slideVariants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 300 : -300,
            opacity: 0,
            scale: 0.8,
            transition: {
                x: { duration: 0.3, ease: "easeOut" },
                scale: { duration: 0.3, ease: "easeOut" },
                opacity: { duration: 0.3, ease: "easeOut" },
            },
        };
    },
    center: {
        x: 0,
        opacity: 1,
        scale: 1,
        transition: {
            x: { duration: 0.3, ease: "easeOut" },
            scale: { duration: 0.3, ease: "easeOut" },
            opacity: { duration: 0.3, ease: "easeOut" },
        },
    },
    exit: (direction) => {
        return {
            x: direction > 0 ? -300 : 300,
            opacity: 0,
            scale: 0.8,
            transition: {
                x: { duration: 0.3, ease: "easeOut" },
                scale: { duration: 0.3, ease: "easeOut" },
                opacity: { duration: 0.2, ease: "easeOut" },
            },
        };
    },
};



const PetCreationFlow = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [direction, setDirection] = useState(0);
    const { addPet } = usePetStorage();

    // Initialize Formik for form state management and validation
    const formik = useFormik({
        initialValues: {
            name: "",
            pet_type: "",
            age: "",
            ageType: "Years",
            parentEmail: localStorage.getItem('unregistered-email') ? localStorage.getItem('unregistered-email') : "",
        },
        validationSchema: validationSchemas[currentStep], // Use the right schema for the current step
        validateOnMount: true,  // Validate on mount to update button state correctly
        onSubmit: async (values) => {
            const creationRes = await handleAccountCreation(values);
            if (!creationRes) {
                toast.error("Failed to create pet profile. Please try again later.");
                return;
            }
            navigate(paths.pets, { state: { newPet: values, isNewPetOwner: true } });
        },
    });

    // Get the date of birth from the age and ageType
    const getDateOfBirth = (age, ageType) => {
        const today = new Date();
        let date_of_birth = new Date(today);
        if (ageType === "Years") {
            date_of_birth.setFullYear(today.getFullYear() - age);
        } else if (ageType === "Months") {
            date_of_birth.setMonth(today.getMonth() - age);
        } else if (ageType === "Days") {
            date_of_birth.setDate(today.getDate() - age);
        }
        // format the date to yyyy-mm-dd
        date_of_birth = date_of_birth.toISOString().split('T')[0];
        
        return date_of_birth;
    };


    const handleAccountCreation = async (values) => {
        try {
            const {
                name,
                pet_type,
                age,
                ageType,
                parentEmail,
            } = values;

            const date_of_birth = getDateOfBirth(age, ageType) || null;
            const parentEmailClean = parentEmail.toLowerCase().trim();
            if (!api) {
                throw new Error('API client not found');
            }

            // POST request to create a pet profile
            const response = await api.post(`pet/pet/create-by-email/${parentEmailClean}`, {
                name,
                pet_type,
                date_of_birth,
            });

            if (response.error) {
                toast.error("Failed to create pet profile");
                throw new Error(response.error.message);
            }
            
            // make sure there's an id in the response
            if (!response.id) {
                toast.error("Failed to create pet profile");
                throw new Error("Pet ID not found in response");
            }

            // on success, clear localStorage
            localStorage.removeItem('auth');
            localStorage.removeItem('unregistered-email');
            localStorage.removeItem('customer');
            localStorage.clear();

            // only redirect if the local storage is cleared
            if (localStorage.getItem('auth')) {
                throw new Error('Failed to save password');
            }

            localStorage.setItem('auth', JSON.stringify({
                flow: 'pet-creation',
                state: 'logged-in',
                data: {
                    email: parentEmailClean,
                }
            }));

            // check if response has user as key and rename it to user_id (for consistency from the backend)
            if (!response.customer_id) {
                throw new Error('Customer ID not found in response');
            }

            const customer_response = await api.get(`cms/customer/retrieve?customer_id=${response.customer_id}`);

            if (!customer_response?.id) {
                throw new Error('Customer not found');
            }

            localStorage.setItem('customer', JSON.stringify(customer_response || {}));


            addPet({...response});

            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }


    // Function to go to the next step if valid
    const nextStep = async () => {
        const isValid = await formik.validateForm();
        if (Object.keys(isValid).length === 0) {
            if (currentStep < steps.length - 1) {
                setDirection(1);
                setCurrentStep((prevStep) => prevStep + 1);
            }

            // Submit the form if it's the last step
            if (currentStep === steps.length - 1) {
                formik.handleSubmit();
            }


        } else {
            formik.setErrors(isValid);
        }
    };

    // Go to the previous step
    const prevStep = () => {
        if (currentStep > 0) {
            setDirection(-1);
            setCurrentStep(prevStep => prevStep - 1);
        }
    };

    // Function to handle key down events
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default enter behavior
            if (currentStep === steps.length - 1) {
                formik.handleSubmit(); // Submit only if on the last step
            } else {
                nextStep(); // Move to the next step if not on the last
            }
        }
    };

    return (
        <div className={classes.petCreationWrapper}>
            <div className={classes.petHeaderContainer}>
                <HomeHeader classes={classes} navigate={navigate} />
                <div className={classes.tabsContainer}>
                    <Grid container>
                        <Grid item className={classes.stepTracker}>
                            <Typography style={{
                                color: "#1F1F2A",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "24.8px",
                                textAlign: "left",
                            }} gutterBottom>
                                Create Pet Profile
                            </Typography>
                            <Typography style={{
                                color: "#7019FF",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "24.8px",
                                textAlign: "left",
                            }}>
                                Step {currentStep + 1} of {steps.length}
                            </Typography>
                        </Grid>

                        <motion.div
                            className={classes.progressBar}
                            initial={{ width: "0%" }}
                            animate={{ width: `${(currentStep + 1) / steps.length * 100}%` }}
                            transition={{ duration: 0.5 }}
                        />
                    </Grid>
                </div>
            </div>

            <Grid container className={classes.container}>
                <motion.div
                    key={currentStep}
                    className={classes.form}
                    custom={direction}
                    variants={slideVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                >
                    <Typography
                        style={{
                            color: "#1F1F2A",
                            fontSize: "40px",
                            fontWeight: 600,
                            lineHeight: "46px",
                            textAlign: "left",
                            paddingBottom: "24px",
                            letterSpacing: "-2%"
                        }}
                    >
                        {steps[currentStep].title.replace("{name}", formik.values.name) || steps[currentStep].title}
                        {steps[currentStep].subTitle && (
                            <Typography style={{ color: "#7D7D97", fontSize: "16px", fontWeight: 500, lineHeight: "19.5px", textAlign: "left", marginTop: 12 }}>
                                {steps[currentStep].subTitle}
                            </Typography>
                        )}
                    </Typography>

                    {/* Step-specific form fields */}
                    <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
                        {currentStep === 0 && (
                            <TextField
                                className={classes.input}
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                placeholder={steps[currentStep].placeholder}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        )}

                        {currentStep === 1 && (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                {["Cat", "Dog", "Bird", "Rabbit", "Hamster", "Fish", "Reptile", "Amphibian", "Other"].map(pet_type => (
                                    <Button
                                        key={pet_type.toLowerCase()}
                                        variant="outlined"
                                        onClick={() => formik.setFieldValue("pet_type", pet_type.toLowerCase())}
                                        sx={{
                                            width: "fit-content",
                                            height: "48px",
                                            padding: "0px 24px 0px 24px",
                                            gap: "10px",
                                            borderRadius: "24px",
                                            border: "1px solid #E3E8FF",
                                            color: "#1F1F2A",
                                            backgroundColor: formik.values.pet_type === pet_type.toLowerCase() ? "#F6F7FB" : "#FFFFFF",
                                            fontWeight: formik.values.pet_type === pet_type.toLowerCase() ? 600 : 500,
                                            fontSize: "20px",
                                            lineHeight: "24.38px",
                                            // lowercase
                                            textTransform: "none",
                                        }}
                                    >
                                        {pet_type}
                                    </Button>
                                ))}
                                {formik.errors.pet_type && (
                                    <Typography color="error">{formik.errors.pet_type}</Typography>
                                )}
                            </div>
                        )}

                        {currentStep === 2 && (
                            <div container className={classes.ageInputGroup}>
                                <TextField
                                    className={`${classes.input}`}
                                    type="number"
                                    name="age"
                                    value={formik.values.age}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    color="primary"
                                    sx={{ width: "70%" }}
                                    placeholder={steps[currentStep].placeholder}
                                    error={formik.touched.age && Boolean(formik.errors.age)}
                                    helperText={formik.touched.age && formik.errors.age}
                                />

                                {/* dropdown for age Years, Months, Days */}
                                <TextField
                                    className={`${classes.input} ${classes.ageInputDropdown}`}
                                    select
                                    name="ageType"
                                    value={formik.values.ageType || "Years"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    color="primary"
                                    sx={{ width: "30%" }}
                                    placeholder="Select"
                                    error={formik.touched.ageType && Boolean(formik.errors.ageType)}
                                    helperText={formik.touched.ageType && formik.errors.ageType}
                                >
                                    {["Years", "Months", "Days"].map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                        )}

                        {currentStep === 3 && (
                            <TextField
                                className={classes.input}
                                name="parentEmail"
                                value={formik.values.parentEmail.toLowerCase()}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                color="primary"
                                placeholder={steps[currentStep].placeholder}
                                error={formik.touched.parentEmail && Boolean(formik.errors.parentEmail)}
                                helperText={formik.touched.parentEmail && formik.errors.parentEmail}
                            />
                        )}
                    </form>
                </motion.div>

                {/* Navigation Buttons */}
                <div className={classes.buttonGroup}>
                    {currentStep > 0 && (
                        <Button
                            variant="outlined"
                            onClick={prevStep}
                            disabled={currentStep === 0}
                            sx={{
                                marginRight: "16px",
                                width: "25%",
                                padding: "0px 16px",
                                gap: "8px",
                                borderRadius: "8px",
                                color: "#000",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 8px 0px #1E07511F",
                                border: "0px",
                                height: "48px",
                            }}
                        >
                            Back
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={nextStep}
                        sx={{ width: `${currentStep === 0 ? "100%" : "75%"}`, textTransform: "none", height: "48px" }}
                        disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting || !formik.values[steps[currentStep].stepValue]}
                    >
                        {currentStep === steps.length - 1 ? "Create Pet Profile" : "Next"}
                    </Button>
                </div>
            </Grid>
        </div>
    );
};


export default PetCreationFlow;
