import React, { useState } from 'react';
import {
    IconButton, Drawer, Grid2, Grid, Typography, TextField, CircularProgress, List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import usePetStorage from '../../managers/petsManager'

const useStyles = makeStyles({
    title: {
        '&.MuiTypography-root': {
            fontSize: '18px',
            fontWeight: 600,
            color: 'rgba(31, 31, 42, 1)',
            marginBottom: '8px',
        }
    },
    drawerHeader: {
        padding: '16px',
    },
    searchField: {
        '& .MuiInputBase-root': {
            margin: '0px 20px',
            justifyContent: 'center',
            padding: "0px 20px",
            borderRadius: '8px',
        }
    },
    locationIcon: {
        color: '#7D7D97',
        marginRight: '8px',
    },
    listItem: {
        '&.MuiListItem-root': {
            padding: '14px 16px',
        }
    },
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
        minHeight: '20vh',
    },

});

const PetListItem = ({ pet, setPetField }) => {

    return (
        <ListItem sx={{padding: '0px 20px 12px 20px'}} button onClick={() => setPetField(pet.id)}>
            <Grid2 container sx={{
                gap: '16px',
                alignItems: 'center',
            }}
            >
                <Grid2 item>
                    <img src={pet.photo 
                        ? pet.photo 
                        : `${process.env.PUBLIC_URL}/logo-gray.png`
                    } alt={pet.name} style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '8px',
                        objectFit: 'cover',
                    }} />
                </Grid2>
                <Grid2 item>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24.8px',
                            color: '#1F1F2A',
                        }}
                    >{pet.name}</Typography>
                </Grid2>
            </Grid2>

        </ListItem>
    );
}

const PetsDrawer = ({ title, openDrawer, setOpenDrawer, setPetField }) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
        pets
    } = usePetStorage();


    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    return (
        <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="bottom"
            open={openDrawer}
            onClose={handleCloseDrawer}
        >
            <Grid className={classes.drawerHeader} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <List>
                {Object.values(pets).map((pet, index) => (
                    <PetListItem key={index} pet={pet} setPetField={setPetField} />
                ))}
            </List>
        </Drawer>
    );
};

export default PetsDrawer;
