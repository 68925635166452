import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import DefaultHeader from '../components/Header/defaultHeader.jsx';
import YouAreNewHereContent from '../components/Auth/YouAreNewHereContent.jsx';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const Unregistered = () => {
    const classes = useStyles();

    const [email, setEmail] = useState('');

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if (!auth) {
            return;
        }

        const { data } = JSON.parse(auth);
        if (data?.email) {
            setEmail(data.email);
        } 
    }, []);

    const handleGetStarted = () => {
        localStorage.removeItem('auth');
        localStorage.setItem('unregistered-email', email);
        window.location.href = '/pets/new-parent';
    };

    const handleBack = () => {
        localStorage.removeItem('auth');

        window.location.href = '/login';
    }

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <DefaultHeader />
            </Header>
            <Container maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '0',
                marginTop: '64px',
            }}>
                <div className={classes.contentContainer}>
                    <YouAreNewHereContent />
                </div>
            </Container>
            <div style={{ 
                position: 'fixed', 
                bottom: '0', 
                width: '100%', 
                padding: '16px', 
                background: '#fff', 
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' 
            }}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={handleBack}
                    sx={{
                        width: '30%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                        boxShadow: '0px 2px 8px 0px #1E07511F'
                    }}
                    >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={handleGetStarted}
                    sx={{
                        width: '65%',
                        padding: '16px',
                        marginLeft: '16px',
                        background: '#7019FF',
                        borderRadius: '8px',
                        color: '#fff',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        textTransform: 'none',
                    }}>
                    Get Started
                </Button>
            </div>
        </>
    );
};

export default Unregistered;
