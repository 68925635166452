import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Container, Typography } from '@mui/material';
import Header from '../Header/header';
import PetProfileFormHeader from '../Header/petProfileFormHeader';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants.js';
import MainReminderDetailsForm from './ReminderFormSections/MainReminderDetailsForm';
import PetSelector from '../Buttons/PetSelector';
import PetsDrawer from '../Menus/PetsDrawer.jsx';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    inputTextBox: {
        //padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    reminderTitle: {
        '&.MuiTypography-root': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#7D7D97',
        },
    },

}));


const ReminderForm = ({ initialValues, onSubmit, editPage, petId }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            pet_id: initialValues?.pet || null,
            title: initialValues?.title || '',
            date: initialValues?.date || '', // YYYY-MM-DD
            time: initialValues?.time || '', // HH:MM
            location: initialValues?.location || '',
            type: 'reminder', // Default to reminder for now
        },
        validationSchema: Yup.object({
            pet_id: Yup.number().required('Please select a pet'),
            title: Yup.string().required('Title is required'),
            date: Yup.string().required('Date is required'),
            time: Yup.string().required('Time is required'),
            location: Yup.string().required('Location is required'),
        }),
        onSubmit: onSubmit,
    });

    const handleDiscard = () => {
        formik.resetForm();
        navigate(paths.pets, { state: { goToTab: 'upcoming' } });
    }

    useEffect(() => {
        if (formik.isSubmitting && formik.errors) {
            const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
                if (errorElement) {
                    errorElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [formik.isSubmitting, formik.errors]);

    const [selectedPet, setSelectedPet] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const handlePetSelect = () => {
        setOpenDrawer(true);
    };

    const handleSelectPet = (petId) => {
        setSelectedPet(petId);
        setOpenDrawer(false);
        formik.setFieldValue('pet_id', petId);
    }

    useEffect(() => {
        if (petId) {
            setSelectedPet(petId);
            formik.setFieldValue('pet_id', petId);
        }
    }, [petId]);

    useEffect(() => {
        if (initialValues?.pet) {
            setSelectedPet(initialValues.pet);
        }
    }, [initialValues]);

    return (
        <div>
            <Header>
                <PetProfileFormHeader
                    title={initialValues?.id ? 'Edit Reminder' : 'Add Reminder'}
                    petId={null}
                    hasTabs={false}
                />
            </Header>
            <Container maxWidth="lg" sx={{ padding: "0 20px" }}>
                <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
                    {/* Basic Info Fields */}
                    <div className={classes.detailSectionTitle}>
                        <Typography className={classes.reminderTitle}>
                            A Google Calendar invite for this event will be sent to you via email.
                        </Typography>
                    </div>
                    <div style={{ width: "100%" }}>

                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    Pet
                                </Typography>
                            </div>
                            <PetSelector
                                selectedPet={selectedPet}
                                onSelect={handlePetSelect}
                                disabled={editPage}
                            />
                            <PetsDrawer
                                title="Select a pet"
                                openDrawer={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                                setPetField={(petId) => handleSelectPet(petId)}
                            />
                        </div>
                    </div>

                    <MainReminderDetailsForm formik={formik} />
                    <div className={classes.buttonGroup}>
                        <Button
                            variant="outlined"
                            onClick={handleDiscard}
                            sx={{
                                marginRight: "16px",
                                width: "25%",
                                padding: "0px 16px",
                                gap: "8px",
                                borderRadius: "8px",
                                color: "#000",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 8px 0px #1E07511F",
                                border: "0px",
                            }}
                        >
                            {/* If form has values make it Discard otherwise Cancel */}
                            {Object.keys(formik.values).length > 0 ? "Discard" : "Cancel"}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ width: "75%" }}
                            disabled={formik.isSubmitting || !formik.dirty}
                        >
                            {"Save Reminder"}
                        </Button>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default ReminderForm;