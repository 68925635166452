import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Container } from '@mui/material';
import Header from '../Header/header';
import PetProfileFormHeader from '../Header/petProfileFormHeader';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants.js';
import PetGeneralDetailsForm from './PetProfileFormSections/PetGeneralDetailsForm.jsx';
import PetSocialForm from './PetProfileFormSections/PetSocialForm.jsx';
import PetPersonalityForm from './PetProfileFormSections/PetPersonalityForm.jsx';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    inputTextBox: {
        //padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "20px",
        background: "#fff",
        boxShadow: "2px 0px 12px 0px #00000026",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
}));


const PetForm = ({ initialValues, onSubmit }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const infoRef = useRef(null);
    const personalityRef = useRef(null);
    // const parentsRef = useRef(null);
    const socialRef = useRef(null);
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (index) => {
        // setTabIndex(index);
        // const sectionRefs = [infoRef, personalityRef, parentsRef, socialRef];
        const sectionRefs = [infoRef, personalityRef, socialRef];
        const yOffset = -120; // 120px offset
        const element = sectionRefs[index].current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    };

    const formik = useFormik({
        initialValues: {
            name: initialValues?.name || '',
            breed: initialValues?.breed || '',
            pet_type: initialValues?.pet_type || 'cat',
            sex: initialValues?.sex || 'female',
            is_microchipped: initialValues?.is_microchipped ?? false,
            is_vaccinated: initialValues?.is_vaccinated ?? false,
            is_neutered: initialValues?.is_neutered ?? false,
            date_of_birth: initialValues?.date_of_birth || '',
            address: initialValues?.address || '',
            extra_data: {
                microchip_number: initialValues?.extra_data?.microchip_number || '',
                animal_id: initialValues?.extra_data?.animal_id || '',
                personality: initialValues?.extra_data?.personality || '',
                favorite_food: initialValues?.extra_data?.favorite_food || '',
                favorite_activity: initialValues?.extra_data?.favorite_activity || '',
                biggest_fear: initialValues?.extra_data?.biggest_fear || '',
                weight: {
                    value: initialValues?.extra_data?.weight?.value || null,
                    weight_unit: initialValues?.extra_data?.weight?.weight_unit || 'kg',
                },
            },
            parentEmail: initialValues?.parentEmail || '',
            photo: initialValues?.photo || '',
            social_links: {
                youtube: initialValues?.social_links?.youtube || '',
                instagram: initialValues?.social_links?.instagram || '',
                tiktok: initialValues?.social_links?.tiktok || '',
                x: initialValues?.social_links?.x || '',
                facebook: initialValues?.social_links?.facebook || '',
                custom: initialValues?.social_links?.custom || '',
            },
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Pet name is required'),
            breed: Yup.string().optional(),
            pet_type: Yup.string().required('Pet type is required'),
            sex: Yup.string().required('Pet sex is required'),
            date_of_birth: Yup.date().required('Date of birth is required'),
            address: Yup.string().required('Address is required'),
            extra_data: Yup.object({
                animal_id: Yup.string(),
                microchip_number: Yup.string(),
                personality: Yup.string(),
                favorite_food: Yup.string(),
                favorite_activity: Yup.string(),
                biggest_fear: Yup.string(),
            }).optional(),
            social_links: Yup.object().optional(),
        }),
        onSubmit: async (values) => {
            await onSubmit(values);
        },
    });

    const handleDiscard = () => {
        formik.resetForm();
        navigate(paths.pets);
    }

    useEffect(() => {
        if (formik.isSubmitting && formik.errors) {
            console.log('Errors:', formik.errors);
            const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
                if (errorElement) {
                    errorElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [formik.isSubmitting, formik.errors]);

    return (
        <div>
            {/* <Header> */}
            <PetProfileFormHeader
                title={initialValues?.id ? 'Edit profile' : 'New pet'}
                petId={initialValues?.id || null}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                onTabChange={handleTabChange} // Pass the handler
            />
            {/* </Header> */}
            <Container maxWidth="lg" sx={{ padding: "0 20px" }}>
                <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
                    {/* Basic Info Fields */}
                    <div ref={infoRef} style={{ width: 'inherit' }}>
                        <PetGeneralDetailsForm
                            formik={formik}
                        />
                    </div>
                    <div ref={personalityRef} style={{ width: 'inherit' }}>
                        <PetPersonalityForm
                            formik={formik}
                        />
                    </div>
                    <div ref={socialRef} style={{ width: 'inherit' }}>
                        <PetSocialForm
                            formik={formik}
                        />
                    </div>

                    <div className={classes.buttonGroup}>
                        <Button
                            variant="outlined"
                            onClick={handleDiscard}
                            sx={{
                                marginRight: "16px",
                                width: "25%",
                                padding: "0px 16px",
                                gap: "8px",
                                borderRadius: "8px",
                                color: "#000",
                                backgroundColor: "#fff",
                                boxShadow: "0px 2px 8px 0px #1E07511F",
                                border: "0px",
                            }}
                        >
                            {/* If form has values make it Discard otherwise Cancel */}
                            {Object.keys(formik.values).length > 0 ? "Discard" : "Cancel"}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ width: "75%" }}
                            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                        >
                            {"Save changes"}
                        </Button>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default PetForm;