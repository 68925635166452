import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';
import { apiEndpoints } from '../data/constants';
const api = new ApiClient();
const diaryEndpoints = apiEndpoints.diary;

const useDiaryStorage = (collectionName, petId) => {
  const [storedData, setStoredData] = useState(() => {
    try {
      const data = window.localStorage.getItem(collectionName);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      console.error(`Error initializing ${collectionName} from localStorage:`, error);
      return {};
    }
  });

  const syncLocalStorage = (data) => {
    try {
      // update localStorage
      window.localStorage.setItem(collectionName, JSON.stringify(data));

    } catch (error) {
      console.error(`Error syncing ${collectionName} to localStorage:`, error);
    }
  };

  const setValue = (updateFunc) => {
    setStoredData((prevData) => {
      const updatedData = updateFunc(prevData);
      syncLocalStorage(updatedData);
      return updatedData;
    });
  };

  // Fetch Data by Key
  const fetchItemData = async (entryId) => {
    try {
      const response = await api.get(`${diaryEndpoints.fetch}?diary_id=${entryId}`);
      if (response.error) {
        console.error(`Error fetching ${collectionName}:`, response.error);
        toast.error(`Error fetching ${collectionName}`);
        return;
      }

      const formattedData = response.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setStoredData(formattedData);
      syncLocalStorage(formattedData);
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
      toast.error(`Error fetching ${collectionName}`);
    }
  };

  // Fetch all Data
  const fetchData = useCallback(async (petId) => {
    try {
      const response = await api.get(`${diaryEndpoints.list}?pet_id=${petId}`);
      if (response.error) {
        console.error(`Error fetching ${collectionName}:`, response.error);
        toast.error(`Error fetching ${collectionName}`);
        return;
      }

      const formattedData = response.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setStoredData(formattedData);
      syncLocalStorage(formattedData);

      return formattedData;
      
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
      toast.error(`Error fetching ${collectionName}`);
    }
  }, []);

  // Add Item
  const addItem = async (refId, newItem) => {
    try {
      // refId like petID, userID, etc.
      const response = await api.post((`${diaryEndpoints.create}/${refId}`), newItem);
      if (response.error || !response.id) {
        toast.error(`Error adding ${collectionName}`);
        return;
      }
      setValue((prevData) => ({
        ...prevData,
        [response.id]: { ...response },
      }));

      syncLocalStorage({ ...storedData, [response.id]: { ...response } });
      refreshItems();
      toast.success(`${collectionName} added successfully`);
    } catch (error) {
      console.error(`Error adding ${collectionName}:`, error);
      toast.error(`Error adding ${collectionName}`);
    }
  };

  // Update Item
  const updateItem = async (itemId, newData) => {
    try {
      const response = await api.put(`${diaryEndpoints.update}/${itemId}`, newData);
      if (response.error) {
        toast.error(`Error updating ${collectionName}`);
        return;
      }

      setValue((prevData) => ({
        ...prevData,
        [itemId]: { ...prevData[itemId], ...newData },
      }));
      refreshItems();
      toast.success(`${collectionName} updated successfully`);
    } catch (error) {
      console.error(`Error updating ${collectionName}:`, error);
      toast.error(`Error updating ${collectionName}`);
    }
  };

  // Delete Item
  const deleteItem = async (itemId) => {
    try {
      const response = await api.delete(`${diaryEndpoints.delete}/${itemId}`);
      if (response.error) {
        toast.error(`Error deleting ${collectionName}`);
        return false;
      }

      // setValue((prevData) => {
      //   const updatedData = { ...prevData };
      //   delete updatedData[itemId];
      //   return updatedData;
      // });

      refreshItems();

      toast.success(`${collectionName} deleted successfully`);
      return true;
    } catch (error) {
      console.error(`Error deleting ${collectionName}:`, error);
      toast.error(`Error deleting ${collectionName}`);
      return false;
    }
  };

  // Local Data Accessors
  const getItemById = (itemId) => storedData[itemId] || null;
  const getAllItems = () => Object.values(storedData);


  const refreshItems = useCallback(async () => {
    try {

      await fetchData(petId);
    } catch (error) {
      console.error(`Error refreshing: ${collectionName}`, error);
    }
  }, [fetchData]);

  useEffect(() => {
    refreshItems();
  }, []);

  return {
    data: storedData,
    fetchData,
    fetchItemData,
    addItem,
    updateItem,
    deleteItem,
    getItemById,
    getAllItems,
    refreshItems,
  };
};

export default useDiaryStorage;
