import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import usePetStorage from '../../managers/petsManager';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        cursor: 'pointer',
    },
    selector: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        background: '#FFFFFF',
        borderRadius: '12px',
        gap: '12px',
        minHeight: '64px',
        boxShadow: '0px 2px 8px 0px #1E07511F',
    },
    placeholder: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    placeholderIcon: {
        width: '48px',
        height: '48px',
        borderRadius: '8px',
    },
    petImage: {
        width: '40px',
        height: '40px',
        borderRadius: '8px',
        objectFit: 'cover',
    },
    text: {
        flex: 1,
        color: ({ selected }) => selected ? '#1F1F2A' : '#7D7D97',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24.8px',
    },
    arrowIcon: {
        color: '#7D7D97',
        fontSize: '24px',
    },
}));

const PetSelector = ({ selectedPet: selectedPetId, onSelect, disabled }) => {
    const classes = useStyles({ selected: Boolean(selectedPetId) });
    const [selectedPet, setSelectedPet] = useState(null);
    const {
        pets,
        getPetById
    } = usePetStorage();
    const PlaceholderIcon = () => (
        <div >
            <img className={classes.placeholderIcon} src={`${process.env.PUBLIC_URL}/logo-gray.png`} alt='logo' />
        </div>
    );

    useEffect(() => {
        if (selectedPetId) {
            const pet = getPetById(selectedPetId);
            if (!pet) {
                setSelectedPet(null);
            }
            setSelectedPet(pet);

        }
    }, [selectedPetId, pets, onSelect]);

    return (
        <Box className={classes.container} onClick={disabled ? null : onSelect} disabled={disabled}>
            <Box className={classes.selector}>
                {selectedPet ? (
                    <>
                        <img
                            src={selectedPet.photo || process.env.PUBLIC_URL + '/logo-gray.png'}
                            alt={selectedPet.name}
                            className={classes.petImage}
                        />
                        <Typography className={classes.text}>
                            {selectedPet.name}
                        </Typography>
                    </>
                ) : (
                    <>
                        <PlaceholderIcon />
                        <Typography className={classes.text}>
                            Select a pet
                        </Typography>
                    </>
                )}
                {!disabled && <KeyboardArrowDownIcon className={classes.arrowIcon} />}
            </Box>
        </Box>
    );
};

export default PetSelector;