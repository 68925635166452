import React from 'react';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    backButton: {
        '&.MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
                marginRight: 'auto',
            }
        }
    },
    indicator: {
        backgroundColor: "#1F1F2A",
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 48,
        padding: "12px 16px"
    },
    logo: {
        width: '60px',
    },
}));

const HomeHeader = ({ navigate, classes }) => {
    return (
        <div onClick={() => navigate(paths.pets)} className={classes.logoContainer} style={{ cursor: "pointer" }}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt='logo' />
        </div>
    );
};


const DefaultHeader = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.headerContainer}>
            <HomeHeader classes={classes} navigate={navigate} />
        </div>
    );
};

export default DefaultHeader;
