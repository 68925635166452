import React, { useState } from "react";
import { Typography, Button } from "@mui/material";

const LimitedDescription = ({ description, children, className }) => {
    const [showFullText, setShowFullText] = useState(false);
    const maxCharLimit = 200;

    const truncatedDescription =
        description.length > maxCharLimit
            ? `${description.substring(0, maxCharLimit)}...`
            : description;

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div className={className}>
            {/* Description text with truncation logic */}
            <Typography variant="body1" style={{ display: "inline" }}>
                {showFullText ? description : truncatedDescription}
            </Typography>

            {description.length > maxCharLimit && (
                <Button
                    variant="text"
                    size="small"
                    onClick={toggleShowFullText}
                    sx={{ ml: 1, textTransform: "none", fontWeight: "bold", display: "inline" }}
                >
                    {showFullText ? "Read Less" : "Read More"}
                </Button>
            )}

            {/* Render children, which can include location or any other elements */}
            {children}
        </div>
    );
};

export default LimitedDescription;
