import React, { useEffect } from 'react';
import { Chip, Avatar, IconButton, Typography, Tabs, Tab, Box, Grid, useMediaQuery, useTheme, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { paths, PetsTagsStyle as TagsStyle } from '../../data/constants.js';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    icons: {
        '&.MuiIconButton-root': {
            color: "#1F1F2A",
        },
    },
    tabsContainer: {
        flexGrow: 1,
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tabTitle: {
        '&.MuiTab-root': {
            fontSize: '14px',
            lineHeight: '17.07px',
            color: "#7D7D97",
            fontWeight: 500,
            textTransform: 'none',
        }
    },
    activeTab: {
        '&.MuiTab-root': {
            fontWeight: 600,
            color: "#1F1F2A"
        }
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        justifyContent: 'space-between',
        marginBottom: '16px',

    },
    dummySpace: {
        width: '40px', // same width as backButton to balance the space
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
        justifyContent: 'center',
    },
    chip: {
        maxWidth: 220,
        height: 29,
        borderRadius: '6px !important',
        pointerEvents: 'none',
        "&.MuiChip-root": {
            padding: '6px 8px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '17.07px',
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },
    petName: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '12px',
        "&.MuiTypography-root": {
            // 24px
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '33.6px',
            color: "#1F1F2A"
        },
    },
    petAvatar: {
        display: 'flex',
        alignItems: 'center',
        // space between
        justifyContent: 'space-between',
        // height: 25,
    },
    petAvatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
        marginBottom: '8px',
        padding: '0 4px'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 48,
        padding: "12px 16px"
    },
    logo: {
        width: '60px',
    },
}));

const HomeHeader = ({ navigate, classes, isLoggedIn, currentCustomer }) => {
    const [customerAvatar, setCustomerAvatar] = React.useState(null);

    useEffect(() => {
        if (currentCustomer) {
            setCustomerAvatar(currentCustomer.photo || null);
        }
    }, [currentCustomer]);

    const handleProfileClick = (event) => {
        navigate(paths.pets);
    };

    return (
        <div className={classes.logoContainer} style={{ cursor: 'pointer' }} onClick={() => navigate(paths.pets)}>
            <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt="logo" />
            {isLoggedIn && (
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item >
                        {/* User icon */}
                        <img
                            src={customerAvatar || `${process.env.PUBLIC_URL}/logo-gray.png`}
                            alt="User"
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: '50%',
                                cursor: 'pointer',
                                border: '1px solid #7019FF',
                                objectFit: 'cover',
                            }}
                            onClick={handleProfileClick}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};


const PetProfileHeader = ({ pet, setTabIndex, tabIndex, onTabChange, handleShareProfileClick, isLoggedIn, isSharing = false, currentCustomer=null }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(paths.pets);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        onTabChange(newValue);
    };

    const handleEditClick = () => {
        navigate(paths.petCreateEdit, { state: { petID: pet?.id } });
    }

    return (
        <div className={classes.petHeaderContainer}>
            {!isSharing ?
                <div className={classes.titleContainer}>
                    <IconButton onClick={handleBackClick} className={classes.icons} sx={{
                        marginLeft: '12px',

                    }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Grid container alignItems="center" justifyContent={'end'}>
                        <IconButton className={classes.icons} onClick={handleEditClick}>
                            <BorderColorOutlinedIcon sx={{ color: '#1F1F2A' }} />
                        </IconButton>
                        <IconButton className={classes.icons} onClick={handleShareProfileClick}>
                            <IosShareOutlinedIcon sx={{ color: '#1F1F2A' }} />
                        </IconButton>
                    </Grid>
                </div>
                : isLoggedIn ? 
                    <HomeHeader navigate={navigate} classes={classes} isLoggedIn={isLoggedIn} currentCustomer={currentCustomer} />
                : <div className={classes.titleContainer}>
                    <div onClick={() => navigate(paths.pets)} className={classes.logoContainer} style={{ cursor: "pointer" }}>
                        <img className={classes.logo} src={`${process.env.PUBLIC_URL}/Treet.svg`} alt='logo' />
                    </div>
                    <Grid container alignItems="center" justifyContent={'end'} sx={{ marginRight: '16px' }}>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(paths.petCreationFlow)}
                            sx={{
                                fontSize: '14px',
                                lineHeight: '21.7px',
                                fontWeight: 600,
                                color: '#1F1F2A',
                                minWidth: '130px',
                                minHeight: '40px',
                                boxShadow: '0px 2px 8px 0px #1E07511F',
                                borderRadius: '8px',
                                border: '0px solid #fff',
                                textTransform: 'none',
                            }}
                        >
                            Create profile
                        </Button>
                    </Grid>
                </div>
            }
            <div className={classes.petAvatarContainer}>
                <div className={classes.petAvatar}>
                    <Avatar
                        alt={pet.name}
                        src={pet.photo || process.env.PUBLIC_URL + '/logo-gray.png'} // Fallback to default avatar
                        variant="square"
                        // Square avatar
                        sx={{
                            width: 120,
                            height: 120,
                            border: "2.22px solid #fff",
                            boxShadow: '0px 2px 12px 0px #00000026',
                            borderRadius: '12px',
                            marginBottom: '16px',
                        }}
                    />
                </div>

                <Typography variant="h6" component="div" className={classes.petName}>
                    {pet.name || 'Unknown'} {/* Fallback for missing name */}
                </Typography>

                {/* Tags */}
                <div className={classes.chips}>
                    {/* Type Chip */}
                    {pet.pet_type && (
                        <Chip
                            label={pet.pet_type[0].toUpperCase() + pet.pet_type.slice(1)} // Capitalize first letter
                            style={TagsStyle.type[pet.pet_type] || TagsStyle.default} // Fallback to default styling
                            className={classes.chip}
                        />
                    )}

                    {/* Breed Chip */}
                    <Chip
                        label={pet?.breed ? pet.breed[0].toUpperCase() + pet.breed.slice(1) : "Mysterious Breed"} // Capitalize first letter
                        style={TagsStyle.breed || TagsStyle.default}
                        className={classes.chip}
                    />

                    {/* Sex Chip */}
                    {pet.sex && (
                        <Chip
                            label={pet.sex[0].toUpperCase() + pet.sex.slice(1)} // Capitalize first letter
                            style={TagsStyle.sex[pet.sex] || TagsStyle.default}
                            className={classes.chip}
                        />
                    )}
                </div>
            </div>
            <div className={classes.tabsContainer}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="#1F1F2A"
                    TabIndicatorProps={{ style: { background: "#1F1F2A" } }}
                    variant="fullWidth"
                >
                    <Tab className={`${classes.tabTitle} ${tabIndex === 0 ? classes.activeTab : ''}`}  label="Bio" />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 1 ? classes.activeTab : ''}`}  label="Parents" />
                    <Tab className={`${classes.tabTitle} ${tabIndex === 2 ? classes.activeTab : ''}`}  label="Diary" />
                </Tabs>
            </div>
        </div>
    );
};

export default PetProfileHeader;
