import React from 'react';

const PetProfileDivider = () => {
    // This component is a simple divider that can be used to separate sections in the PetProfilePage
    return (
        <div style={{ padding: "24px 0" }}>
            <hr style={{ border: "1px solid var(--Mid-Blue, #E3E8FF)", margin: 0 }} />
        </div>
    );
};

export default PetProfileDivider;
