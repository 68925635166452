import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        paddingLeft: '16px',
        paddingTop: '16px',
        gap: '8px',
    },
    iconText: {
        fontSize: '18px',
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    sectionTitleMobile: {
        // paddingTop: '60%',
        // paddingBottom: '2%',
        paddingLeft: '16px',
        textAlign: 'left',
    },
    sectionTitleDesktop: {
        // paddingTop: '15%',
        // paddingBottom: '2%',
        paddingLeft: '16px',
        textAlign: 'left',
    },
    inputTextBox: {
        padding: '16px',
        marginTop: '8px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
        // center component to the middle of the page
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    googleButton: {
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '24.8px',
        fontWeight: 600,
    },
}));

const YouAreNewHereContent = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [email, setEmail] = useState('');

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if (!auth) {
            window.location.href = '/login';
        }

        const { data } = JSON.parse(auth);
        if (data?.email) {
            setEmail(data.email);
        } else {
            console.error('No email found in auth data');
            window.location.href = '/login';
        }
    }, []);

    return (
        <>
            <div className={classes.detailSection}>
                <Typography variant="h4" component="div" className={
                    isMobile ? classes.sectionTitleMobile : classes.sectionTitleDesktop
                }>
                    <span style={{fontWeight: 600}}>Looks like you're <br />new here!</span>
                </Typography>

                <Typography variant="body1" component="div" className={classes.iconContainer}>
                    <span className={classes.iconText}>
                        We couldn't find an account registered under <span style={{
                            strokeWidth: '0.5px',
                            fontWeight: 650,
                            color: '#7019FF',
                        }}>{email}</span>.
                        <br />
                        <br />
                        Would you like to get started with your very first pet profile?
                    </span>
                </Typography>
            </div>
        </>
    );
};

export default YouAreNewHereContent;
