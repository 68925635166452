import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import usePetStorage from '../../managers/petsManager';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants';
const PetProfileCarousel = ({ pet }) => {
  const navigate = useNavigate();
  const { getPetAgeByDOB } = usePetStorage();
  const [petAge, setPetAge] = useState('Mysterious Age');
  const [petDOB, setPetDOB] = useState('Mysterious Birthdate');
  const [petWeight, setPetWeight] = useState('Mysterious Weight');
  useEffect(() => {
    if (pet?.age) {
      setPetAge(`${pet.age} ${pet.ageType}`);
    }
    if (pet?.date_of_birth) {
      const petAge = getPetAgeByDOB(pet.date_of_birth);
      setPetAge(petAge);

      const petDOBFormatted = new Date(pet.date_of_birth).toLocaleDateString('en-UK', { year: 'numeric', month: 'long', day: 'numeric' });
      setPetDOB(petDOBFormatted);
    }

    if (pet?.extra_data?.weight && pet?.extra_data?.weight?.value) {
      setPetWeight(`${pet.extra_data.weight.value} ${pet.extra_data?.weight.weight_unit}`);
    }
  }, [pet, getPetAgeByDOB]);

  // List of children to be displayed in the carousel
  const childrenList = [
    {
      title: 'AGE',
      content: petAge || 'Mysterious Age',
      background: "linear-gradient(180deg, #EEF5FF 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/cake.svg`,
    },
    {
      title: 'BIRTHDATE',
      content: petDOB || 'Mysterious Birthdate',
      background: "linear-gradient(180deg, #FAEBFF 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/balloon.svg`,
    },
    {
      title: 'WEIGHT',
      content: petWeight || 'Mysterious Weight',
      background: 'linear-gradient(180deg, #FFF8EE 0%, #FFFCF9 100%)',
      icon: `${process.env.PUBLIC_URL}/weight.svg`,
    },
    {
      title: 'ADDRESS',
      // The last three words of the address are displayed
      content: pet?.address?.split(' ').slice(-4).join(' ') || 'Mysterious Address',
      background: "linear-gradient(180deg, #EEFFFE 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/location.svg`,
    }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        alignItems: 'center',
        '& > *': {
          flexShrink: 0,
          marginRight: '16px', // Spacing between cards
        },
        '&::-webkit-scrollbar': { display: 'none' }, // Optional: hide scrollbar
      }}
    >
      {/* Cards list from prop */}
      {childrenList.map((child, index) => (
        <Card key={index}
          sx={{
            width: 'fit-content', borderRadius: "8px", marginTop: "24px", marginBottom: "24px",
            boxShadow: "0px 2px 8px 0px #1E07511F",
            cursor: child.content.includes('Mysterious') ? 'pointer' : 'default', // Optional: Change cursor
          }}>
          <CardContent
            onClick={() => child.content.includes('Mysterious') ? navigate(paths.petCreateEdit, { state: { petID: pet?.id } }) : null} // Optional: Add click event
            sx={{ background: child.background, display: 'flex', padding: "12px 16px 12px 12px !important", alignContent: "center" }}>
            <img style={{ marginRight: '16px' }} src={child.icon} alt="icon" width="40" height="40" />
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <Typography sx={{
                paddingBottom: "4px",
                textAlign: "left",
                color: '#7D7D97',
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "18.6px",
              }}>
                {child.title}
              </Typography>
              <Typography sx={{
                paddingBottom: "4px",
                textAlign: "left",
                color: '#1F1F2A',
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "21.7px",
              }}>
                {child.content}
              </Typography>
            </div>
          </CardContent>
        </Card>
      ))}

    </Box>
  );
};

export default PetProfileCarousel;
