import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';
import ApiClient from '../../utils/client';
import { useMediaQuery } from '@mui/material';

const api = new ApiClient();

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    sectionTitleMobile: {
        paddingTop: '40%',
        paddingBottom: '5%',
    },
    sectionTitleDesktop: {
        paddingTop: '10%',
        paddingBottom: '5%',
    },
    inputTextBox: {
        // padding: '16px',
        width: '100%',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
                border: '1px solid #E3E8FF',
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
    },
    googleButton: {
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '24.8px',
        fontWeight: 600,
    },
}));

const LoginOrSignupForm = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (email !== '' && email.includes('@') && email.includes('.')) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [email]);

    useEffect(() => {
        localStorage.removeItem('auth');
        localStorage.removeItem('unregistered-email');
        localStorage.removeItem('customer');
        localStorage.removeItem('pets');
    }, []);

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => handleGoogleLoginSuccess(tokenResponse),
        onFailure: error => handleGoogleLoginFailure(error),
    });

    const handleGoogleLoginSuccess = async (tokenResponse) => {
        // get access token from response
        const { access_token } = tokenResponse;

        try {
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });

            const userInfo = await userInfoResponse.json();
            const { email, given_name, name } = userInfo;

            // set "verified" to true on the backend side
            const response = await api.post('cms/customer/create', {
                email,
                name: given_name || name,
                verified: true,
            });

            if (!response) {
                toast.error('Failed to get user response');
                return;
            }

            // clear localStorage
            localStorage.setItem('auth', JSON.stringify({
                flow: 'login-or-signup-google',
                state: 'logged-in',
                data: {
                    email,
                    token: access_token,
                },
            }));
            
            // check if response has user as key and rename it to user_id (for consistency from the backend)
            if (response?.user) {
                response.user_id = response.user;
                delete response.user;
            }
            localStorage.setItem('customer', JSON.stringify(response || {}));

            // redirect
            window.location.href = '/pets';

        } catch (error) {
            console.error('Failed to fetch user info:', error);
            toast.error('Failed to fetch user info. Please try again.');
        }
    };

    const handleGoogleLoginFailure = (error) => {
        localStorage.removeItem('auth');
        localStorage.removeItem('customer');
        localStorage.removeItem('pets');

        console.error('Google login failed:', error);
        toast.error('Google login failed. Please try again.');
    };

    const handleNext = async () => {
        setDisabled(true);

        if (!api) throw new Error('API client not found');

        try {
            const response = await api.get(
                `cms/auth/check-if-email-exists?email=${email}`
            );

            if (response.error) {
                toast.error(response.error);
                setDisabled(false);
                return;
            }

            localStorage.setItem('auth', JSON.stringify({
                flow: 'login-or-signup',
                data: {
                    email,
                }
            }));

            if (response?.exists && response?.verified) {
                // proceed to enter password
                window.location.href = '/password';
                return;
            }

            if (response?.exists && !response?.verified) {
                // proceed to verify email
                window.location.href = `/verify-email?email=${email}&t=temp`;
                return;
            }

            // proceed to signup
            window.location.href = '/unregistered';

        } catch (error) {
            setDisabled(false);
            console.error(error);
        }
    }

    return (
        <>
            <div className={classes.detailSection}>

                {/* main title */}
                <Typography variant="h4" component="div" className={
                    isMobile ? classes.sectionTitleMobile : classes.sectionTitleDesktop
                }>
                    <span style={{fontWeight: 600}}>Login or signup</span>
                </Typography>

                {/* email field */}
                <TextField
                    align="left"
                    type="email"
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    className={classes.inputTextBox}
                    placeholder="Enter your email address"
                    sx={{
                        cursor: 'text',// : 'pointer',
                        color: '#1F1F2A',// : '#7D7D97',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 500,
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: false,
                        }
                    }}
                />

                {/* next button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        marginTop: '16px',
                        textTransform: 'none',
                    }}
                    disabled={disabled}
                >
                    Next
                </Button>

                <Divider textAlign="center" style={{
                    paddingTop: '30px',
                    paddingBottom: '30px',
                }}>
                    <Typography variant="body1" color="textSecondary">
                        OR
                    </Typography>
                </Divider>

                {/* social login button */}
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={googleLogin}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                        boxShadow: '0px 2px 8px 0px #1E07511F',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/google.svg`} alt="google icon" className={classes.icon} />
                    Login with Google
                </Button>

                {/* <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        marginTop: '24px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/facebook.svg`} alt="facebook icon" className={classes.icon} />
                    Login with Facebook
                </Button> */}

                {/* <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '100%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        marginTop: '24px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                        <img src={`${process.env.PUBLIC_URL}/authIcons/apple.svg`} alt="apple icon" className={classes.icon} />
                    Login with Apple
                </Button> */}
            </div>
        </>
    );
};

export default LoginOrSignupForm;
