import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PetForm from '../components/Forms/PetForm';
import { paths } from '../data/constants';
import usePetStorage from '../managers/petsManager';

const PetCreateEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [petID, setPetID] = useState(location.state?.petID);


    useEffect(() => {
        // if there's no auth in localStorage, redirect to login
        const auth = localStorage.getItem('auth');
        if (!auth) {
            navigate(paths.login);
        }

        const authJSON = JSON.parse(auth || '{}');
        if (!authJSON.state || authJSON.state !== 'logged-in') {
            navigate(paths.login);
        }
    }, [navigate]);

    // Ensure navigate is only called once
    useEffect(() => {
        if (!petID) {
            navigate(paths.petCreate, { replace: true });
        }
    }, [petID, navigate]);

    // apply manager
    const {
        pets,
        updatePet,
        addPet,
    } = usePetStorage();

    const pet = Object.values(pets).find((pet) => pet.id === petID) || {};

    const handleSubmit = (values) => {
        if (petID) {
            updatePet(petID, values);
        } else {
            const petID = addPet(values);
            setPetID(petID);
        }
        
        if (petID) {
            navigate(paths.petProfile(petID));
        } else {
            navigate(paths.pets);
        }
    };

    return (
        <PetForm initialValues={pet} onSubmit={handleSubmit} />
    )
};

export default PetCreateEdit;