import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';
import { apiEndpoints } from '../data/constants';
import usePetParentStorage from './parentManager';
const api = new ApiClient();
const reminderEndpoints = apiEndpoints.reminder;

const useReminderStorage = (collectionName) => {
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [storedData, setStoredData] = useState(() => {
    try {
      const data = window.localStorage.getItem(collectionName);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      console.error(`Error initializing ${collectionName} from localStorage:`, error);
      return {};
    }
  });
  
  const { getCurrentPetParent } = usePetParentStorage('customer');

  const getUserId = useCallback(async () => {
    try {
      const parent = await getCurrentPetParent();
      const user = parent?.user_id || null;
      setUserId(user);
      return user;
    } catch (error) {
      console.error('Error fetching userId:', error);
      return null;
    }
  }, [getCurrentPetParent]);

  

  const syncLocalStorage = (data) => {
    try {
      // update localStorage
      window.localStorage.setItem(collectionName, JSON.stringify(data));

    } catch (error) {
      console.error(`Error syncing ${collectionName} to localStorage:`, error);
    }
  };

  const setValue = (updateFunc) => {
    setStoredData((prevData) => {
      const updatedData = updateFunc(prevData);
      syncLocalStorage(updatedData);
      return updatedData;
    });
  };

  // Fetch Data by Key
  const fetchItemData = async (entryId) => {
    try {
      const response = await api.get(`${reminderEndpoints.fetch}?item_id=${entryId}`);
      if (response.error) {
        console.error(`Error fetching ${collectionName}:`, response.error);
        toast.error(`Error fetching ${collectionName}`);
        return;
      }

      const formattedData = response.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setStoredData(formattedData);
      syncLocalStorage(formattedData);
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
      toast.error(`Error fetching ${collectionName}`);
    }
  };

  // Fetch all Data
  const fetchData = useCallback(async () => {
    try {
      if (!userId) {
        console.error('No userId provided');
        return;
      }
      setIsLoading(true);
      const response = await api.get(`${reminderEndpoints.list}/${userId}`);
      if (response.error) {
        console.error(`Error fetching ${collectionName}:`, response.error);
        toast.error(`Error fetching ${collectionName}`);
        return;
      }

      const formattedData = response.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setStoredData(formattedData);
      syncLocalStorage(formattedData);
      
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
      toast.error(`Error fetching ${collectionName}`);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  // Add Item
  const addItem = async (refId, newItem) => {
    try {
      // refId like petID, userID, etc.
      const response = await api.post((`${reminderEndpoints.create}/${refId}`), newItem);
      if (response.error || !response.id) {
        toast.error(`Error adding ${collectionName}`);
        return;
      }
      setValue((prevData) => ({
        ...prevData,
        [response.id]: { ...response },
      }));

      syncLocalStorage({ ...storedData, [response.id]: { ...response } });
      refreshItems();
      toast.success(`${collectionName} added successfully`);
    } catch (error) {
      console.error(`Error adding ${collectionName}:`, error);
      toast.error(`Error adding ${collectionName}`);
    }
  };

  // Update Item
  const updateItem = async (itemId, newData) => {
    try {
      const response = await api.put(`${reminderEndpoints.update}/${itemId}`, newData);
      if (response.error) {
        toast.error(`Error updating ${collectionName}`);
        return;
      }

      setValue((prevData) => ({
        ...prevData,
        [itemId]: { ...prevData[itemId], ...newData },
      }));
      refreshItems();
      toast.success(`${collectionName} updated successfully`);
    } catch (error) {
      console.error(`Error updating ${collectionName}:`, error);
      toast.error(`Error updating ${collectionName}`);
    }
  };

  // Delete Item
  const deleteItem = async (itemId) => {
    try {
      const response = await api.delete(`${reminderEndpoints.delete}/${itemId}`);
      if (response.error) {
        toast.error(`Error deleting ${collectionName}`);
        return;
      }

      setValue((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[itemId];
        return updatedData;
      });

      refreshItems();

      toast.success(`${collectionName} deleted successfully`);
    } catch (error) {
      console.error(`Error deleting ${collectionName}:`, error);
      toast.error(`Error deleting ${collectionName}`);
    }
  };

  // Local Data Accessors
  const getItemById = (itemId) => storedData[itemId] || null;
  const getAllItems = () => Object.values(storedData);


  const refreshItems = useCallback(async () => {
    try {
      await fetchData();
    } catch (error) {
      console.error(`Error refreshing: ${collectionName}`, error);
    }
  }, [fetchData]);

  useEffect(() => {
    getUserId();
  }, []); // Run once on mount

  // Separate effect for fetching data when userId changes
  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId, fetchData]);

  return {
    data: storedData,
    isLoading,
    fetchData,
    fetchItemData,
    addItem,
    updateItem,
    deleteItem,
    getItemById,
    getAllItems,
    refreshItems,
  };
};

export default useReminderStorage;
