import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
const useStyles = makeStyles((theme) => ({
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        // borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        padding: '16px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // padding: '0 16px',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',

    },
}));

const Header = ({ children, navContainer = null, itemsContainer = null  }) => {
    const classes = useStyles();
    return (
        <Grid container className={navContainer ?? classes.navContainer}> 
            <div maxWidth="lg" className={itemsContainer ?? classes.itemsContainer}>
                {React.Children.map(children, (child, index) => (
                    <Grid item key={index} className={classes.item}>
                        {child}
                    </Grid>
                ))}
            </div>
        </Grid>
    );
};

export default Header;
