import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReminderForm from '../components/Forms/ReminderForm';
import { paths } from '../data/constants';
import useReminderStorage from '../managers/reminderManager';
// circular loading
import CircularProgress from '@mui/material/CircularProgress';
const PetReminderCreate = () => {
    const navigate = useNavigate();
    const { profileId, itemId } = useParams();
    const [entry, setEntry] = useState({});

    // Apply diary manager
    const {
        data: items,
        addItem: addEntry,
        updateItem: updateEntry,
        getItemById: getEntryById,
    } = useReminderStorage('reminder');

    useEffect(() => {
        const fetchEntry = async () => {
            if (itemId) {
                const fetchedEntry = await getEntryById(itemId);
                if (fetchedEntry) {
                    setEntry(fetchedEntry);
                }
            }
        };
        fetchEntry();
    }, [itemId, getEntryById]);

    const handleSubmit = (values) => {
        if (itemId) {
            updateEntry(itemId, values);
        } else {
            addEntry(values.pet_id, values);
        }

        navigate(paths.pets, { goToTab: 'pets' });
    };

    return ({ itemId } ?
        <ReminderForm key={entry.id} initialValues={entry} editPage={itemId} petId={profileId} onSubmit={handleSubmit} /> :
        (<CircularProgress />)
    );
};

export default PetReminderCreate;