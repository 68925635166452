import React, { useState, useRef, useEffect } from 'react';
import PetGeneralDetails from '../components/PetProfileSections/PetGeneralDetails';
import PetParentsDetails from '../components/PetProfileSections/PetParentsDetails';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import usePetStorage from '../managers/petsManager';
import PetProfileDivider from '../components/Dividers/PetProfileDivider';
import Header from '../components/Header/header';
import PetProfileHeader from '../components/Header/petProfileHeader';
import PetDiary from '../components/PetProfileSections/PetDiary';
import DefaultSpeedDial from '../components/SpeedDial/defaultSpeedDial';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { useNavigate, useLocation } from 'react-router-dom';
import { paths } from '../data/constants';
import { CircularProgress } from '@mui/material';
import ShareProfileDrawer from '../components/Menus/ShareProfileDrawer';
import { Backdrop } from '@mui/material';

const SignUpInvitation = () => {
    const navigate = useNavigate();
    const onClickSignUp = () => {
        navigate(paths.petCreationFlow);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
            padding: '20px',
            background: 'linear-gradient(180deg, #F3F1FF 0%, #FFFFFF 100%)',
            boxShadow: '0px 2px 8px 0px #1E07511F',
            borderRadius: '12px',
            width: '100%',
        }}>
            <Grid container justifyContent="center" alignItems="center" direction="column" gap={2}>
                <Grid item>
                    <Typography variant="h6" align="center" style={{
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '24.38px',
                        textAlign: 'center',
                        color: '#1F1F2A'
                    }}>Create your own pet profile!</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center" style={{
                        color: '#7D7D97',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '21.7px',
                        textAlign: 'center',
                    }}>Create your own pet profile and join the Treet community today. </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    maxWidth: '600px',
                    padding: '0px 20px 0px 20px',
                    width: '100%',
                }}
            >
                <Button
                    onClick={onClickSignUp}
                    variant="contained"
                    sx={{
                        width: '100%',
                        height: '48px',
                        fontSize: '16px',
                        lineHeight: '19.5px',
                        fontWeight: 600,
                    }}
                >
                    Create pet profile
                </Button>
            </Box>
        </div >
    );
}



const PetProfile = ({ isSharing }) => {
    const { profileId } = useParams();
    const location = useLocation();
    const { showShareDialog } = location.state || false;
    const queryParams = new URLSearchParams(location.search);
    const profileType = queryParams.get('type');
    const navigate = useNavigate();
    const actions = [
        { icon: <MedicalServicesIcon />, name: 'New Entry', handler: () => { navigate(paths.petEntryCreate(profileId)) } },

    ];
    const [tabIndex, setTabIndex] = useState(0);
    // scroll to top when component is mounted
    const aboutRef = useRef(null);
    const parentsRef = useRef(null);
    const medicalRef = useRef(null);
    // Function to handle tab change and scroll to section
    const handleTabChange = (index) => {
        setTabIndex(index);
        const sectionRefs = [aboutRef, parentsRef, medicalRef];
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' });
    };


    const { pets, isLoading, getPetById, fetchPetByPetId } = usePetStorage();
    const [pet, setPet] = useState(null);
    const [isValidatingProfile, setIsValidatingProfile] = useState(true);
    useEffect(() => {
        (async () => {
            if (!profileId) return;
            const pet = await fetchPetByPetId(profileId);
            setPet(pet);
        })();
    }, [profileId, fetchPetByPetId]);

    // Check if user is logged in
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState(null);
    useEffect(() => {
        const auth = localStorage.getItem('auth');
        const customer = localStorage.getItem('customer');
        const authJSON = JSON.parse(auth || '{}');
        if (authJSON.state === 'logged-in') {
            setIsLoggedIn(true);
        }
        if (customer) {
            setCurrentCustomer(JSON.parse(customer));
        }
    }, []);


    useEffect(() => {
        // Validate profile page to ensure user is authorized to view the page if not sharing
        const validateProfile = async () => {
            if (!isSharing && pet) {
                const auth = localStorage.getItem('auth');
                const authJSON = JSON.parse(auth || '{}');
                const customer = JSON.parse(localStorage.getItem('customer') || '{}');

                if (!auth || authJSON.state !== 'logged-in' || !customer) {
                    navigate(paths.petShareProfile(profileId) + '?type=basic');
                    return;
                }

                if (pet.user_id !== customer.user_id) {
                    navigate(paths.petShareProfile(profileId) + '?type=basic');
                    return;
                }
            }
            setIsValidatingProfile(false);
        };

        if (pet) {
            validateProfile();
        }
    }, [isSharing, pet, profileId, navigate]);

    const [openShareProfileDrawer, setOpenShareProfileDrawer] = useState(false);
    const handleShareProfileClick = () => {
        setOpenShareProfileDrawer(true);
    }

    useEffect(() => {
        if (showShareDialog) {
            handleShareProfileClick();
        }
    }, [showShareDialog]);


    return (


        <div>
            <Backdrop open={isValidatingProfile || isLoading || !pet} style={{ zIndex: 1000 }}>
                <CircularProgress sx={{
                    color: 'primary',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-20px',
                    marginLeft: '-20px',
                }} />
            </Backdrop>
            {pet && !isValidatingProfile &&
                <>
                    <Header>
                        <PetProfileHeader
                            pet={pet}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            onTabChange={handleTabChange} // Pass the handler
                            handleShareProfileClick={handleShareProfileClick}
                            isSharing={isSharing}
                            isLoggedIn={isLoggedIn}
                            currentCustomer={currentCustomer}
                        />
                    </Header>
                    <Container maxWidth="md" sx={{ padding: '24px 20px' }}>
                        <div ref={aboutRef}>
                            <PetGeneralDetails pet={pet} isSharing={isSharing} profileType={profileType} />
                            <PetProfileDivider />
                        </div>
                        <div ref={parentsRef}>
                            {/* Parents Section */}
                            <PetParentsDetails pet={pet} isSharing={isSharing} profileType={profileType} />
                            <PetProfileDivider />
                        </div>
                        <div ref={medicalRef}>
                            {/* Medical Section */}
                            <PetDiary pet={pet} profileId={profileId} isSharing={isSharing} />
                        </div>
                        {/* SignUp invitation Section*/}
                        {isSharing && !isLoggedIn &&
                            <>
                                <PetProfileDivider />
                                <SignUpInvitation />
                            </>
                        }
                    </Container>
                </>
            }
            {!isSharing &&
                <>
                    <DefaultSpeedDial pet={pet} />
                    <ShareProfileDrawer
                        open={openShareProfileDrawer}
                        onClose={() => setOpenShareProfileDrawer(false)}
                        pet={pet}
                    />
                </>
            }
        </div>
    )

};

export default PetProfile;