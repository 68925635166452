import React, { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { Box } from '@mui/material';
import ReusableDrawer from '../Menus/ReusableDrawer';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants';

const DefaultSpeedDial = ({pet}) => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const menuItems = [
        {
            label: 'Diary entry',
            icon: <img src={`${process.env.PUBLIC_URL}/profileIcons/diary.svg`} alt="Diary" style={{
                height: 24,
                width: 24,
            }} />,
            action: () => navigate(paths.petEntryCreate(pet.id)),
        },
        {
            label: 'Reminder',
            icon: <img
                src={`${process.env.PUBLIC_URL}/profileIcons/notification.svg`}
                alt="Reminder"
                style={{
                    height: 24,
                    width: 24,
                }} />,
            action: () => navigate(paths.petReminderCreateWithProfile(pet.id)),
        },
    ];

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <Box
            sx={{
                transform: 'translateZ(0px)',
                flexGrow: 1,
                zIndex: 10000,
                position: 'fixed',
                bottom: 20,
                right: 16,
            }}
        >
            {/* SpeedDial for opening the Drawer */}
            {!drawerOpen &&

                <SpeedDial
                    ariaLabel="SpeedDial example"
                    icon={<SpeedDialIcon />}
                    onClick={handleDrawerOpen}
                    // disable onHover
                    open={false}
                />
            }

            {/* ReusableDrawer - Always mounted */}
            <ReusableDrawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                title="Add new"
                menuItems={menuItems}
            />
        </Box>
    );
};

export default DefaultSpeedDial;
