import React from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Button, useMediaQuery, useTheme,
    IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    drawerPaper: {
        borderRadius: '8px 8px 0 0',
    },
    button: {
        "&.MuiButton-root": {
            fontWeight: 600,
            padding: '0px, 16px, 0px, 16px',
            border: '1px solid #E3E8FF',
            boxShadow: 'none',
            color: '#1F1F2A',
            textTransform: 'none',
            "&:hover": {
                boxShadow: 'none',
            }
        },
    },
    buttonGroup: {
        // make the button group sticky at the bottom
        display: 'flex',
        // position: 'fixed',
        // bottom: '0',
        height: 88,
        // left: '50%',
        // transform: 'translateX(-50%)',
        // zIndex: 1000,
        // maxWidth: '600px', // Make it phone size only at 600px
        width: '100%',
        padding: "20px",
        background: "#fff",
        // boxShadow: "2px 0px 12px 0px #00000026",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
});


const ConfirmationDialog = ({
    open,
    handleClose,
    handleConfirm,
    title = "Confirm Action",
    content = "Are you sure you want to proceed?",
    confirmLabel = "Confirm"
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const renderContent = () => (
        <>
            <DialogTitle style={{ fontWeight: 600 }} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
            }}>
                {title}
                {/* X icon */}
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                padding: '0px 20px'
            }}>
                <DialogContentText style={{ fontWeight: 500 }}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <div className={classes.buttonGroup}>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                        marginRight: "16px",
                        width: "25%",
                        padding: "0px 16px",
                        gap: "8px",
                        borderRadius: "8px",
                        color: "#000",
                        backgroundColor: "#fff",
                        boxShadow: "0px 2px 8px 0px #1E07511F",
                        border: "0px",
                        textTransform: "none",
                    }}
                >
                    {/* If form has values make it Discard otherwise Cancel */}
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                        width: "75%",
                        textTransform: "none"
                    }}
                    onClick={handleConfirm}
                >
                    {confirmLabel}
                </Button>
            </div>
        </>
    );

    return isMobile ? (
        <Drawer classes={{ paper: classes.drawerPaper }} anchor="bottom" open={open} onClose={handleClose}>
            {renderContent()}
        </Drawer>
    ) : (
        <Dialog open={open} onClose={handleClose}>
            {renderContent()}
        </Dialog>
    );
};

export default ConfirmationDialog;
