import React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Select, MenuItem, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    timeContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
    },
    timeInput: {
        '& .MuiInputBase-input': {
            padding: '16px',
            width: '48px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            color: '#1F1F2A',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--Light-Grey, #F6F7FB)',
            borderRadius: '8px',
            '& fieldset': {
                border: '2px solid transparent',
            },
            '&:hover fieldset': {
                border: '2px solid transparent',
            },
            '&.Mui-focused fieldset': {
                border: '2px solid #7019FF',
            },
        },
    },
    periodSelect: {
        '& .MuiSelect-select': {
            padding: '16px',
            width: '80px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            color: '#1F1F2A',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--Light-Grey, #F6F7FB)',
            borderRadius: '8px',
            '& fieldset': {
                border: '0px solid #E3E8FF',
            },
            '&:hover fieldset': {
                border: '0px solid #E3E8FF',
            },
            '&.Mui-focused fieldset': {
                border: '0px solid #E3E8FF',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0px solid #E3E8FF',
        }

    },
    separator: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#1F1F2A',
    },
}));

const TimeInput = ({ value, onChange, error, helperText, propName = 'time' }) => {
    const classes = useStyles();

    // Parse initial 24-hour value to 12-hour format
    const parseTimeValue = () => {
        if (!value) return { hours: '', minutes: '', period: 'AM' };

        // Parse HH:MM format
        const match = value.match(/(\d{2}):(\d{2})/);
        if (match) {
            let hours = parseInt(match[1]);
            const minutes = match[2];
            let period = 'AM';

            if (hours >= 12) {
                period = 'PM';
                if (hours > 12) {
                    hours -= 12;
                }
            }
            if (hours === 0) {
                hours = 12;
            }

            return {
                hours: hours.toString().padStart(2, '0'),
                minutes,
                period
            };
        }
        return { hours: '', minutes: '', period: 'AM' };
    };

    const [timeState, setTimeState] = React.useState(parseTimeValue());

    const handleTimeChange = (field, newValue) => {
        let updatedValue = newValue;

        if (field === 'hours') {
            updatedValue = updatedValue.replace(/\D/g, '').slice(0, 2);
            if (parseInt(updatedValue) > 12) updatedValue = '12';
            if (parseInt(updatedValue) < 1 && updatedValue !== '') updatedValue = '01';
        }
        if (field === 'minutes') {
            updatedValue = updatedValue.replace(/\D/g, '').slice(0, 2);
            if (parseInt(updatedValue) > 59) updatedValue = '59';
        }

        const newTimeState = { ...timeState, [field]: updatedValue };
        setTimeState(newTimeState);

        if (newTimeState.hours && newTimeState.minutes) {
            // Convert to 24-hour format
            let hours = parseInt(newTimeState.hours);

            // Convert to 24 hour format
            if (newTimeState.period === 'PM' && hours !== 12) {
                hours += 12;
            } else if (newTimeState.period === 'AM' && hours === 12) {
                hours = 0;
            }

            const formattedTime = `${hours.toString().padStart(2, '0')}:${newTimeState.minutes.padStart(2, '0')}`;
            onChange({ target: { name: propName, value: formattedTime } });
        }
    };

    return (
        <div className={classes.timeContainer}>
            <TextField
                variant="outlined"
                value={timeState.hours}
                onChange={(e) => handleTimeChange('hours', e.target.value)}
                placeholder="00"
                className={classes.timeInput}
                inputProps={{ maxLength: 2 }}
                error={error}
            />
            <Typography className={classes.separator}>:</Typography>
            <TextField
                variant="outlined"
                value={timeState.minutes}
                onChange={(e) => handleTimeChange('minutes', e.target.value)}
                placeholder="00"
                className={classes.timeInput}
                inputProps={{ maxLength: 2 }}
                error={error}
            />
            <Select
                value={timeState.period}
                onChange={(e) => handleTimeChange('period', e.target.value)}
                className={classes.periodSelect}
                error={error}
                variant="outlined"
                sx={{
                    boxShadow: '0px 2px 8px 0px #1E07511F',
                    borderRadius: '8px',
                }}
            >
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="PM">PM</MenuItem>
            </Select>
            {error && helperText && (
                <Typography color="error" variant="caption">
                    {helperText}
                </Typography>
            )}
        </div>
    );
};

export default TimeInput;