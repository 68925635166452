import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DiaryEntryForm from '../components/Forms/DiaryEntryForm';
import { paths } from '../data/constants';
import useDiaryStorage from '../managers/diaryManager';
// circular loading
import CircularProgress from '@mui/material/CircularProgress';
const DiaryEntryCreate = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    const { profileId, entryID } = useParams();
    const [entry, setEntry] = useState({});
    // Apply diary manager
    const {
        data: entries,
        addItem: addEntry,
        updateItem: updateEntry,
        getItemById: getEntryById,
    } = useDiaryStorage('diary', profileId);

    useEffect(() => {
        const fetchEntry = async () => {
            if (entryID) {
                const fetchedEntry = await getEntryById(entryID);
                if (fetchedEntry) {
                    setEntry(fetchedEntry);
                }
            }
        };
        fetchEntry();
    }, [entryID, getEntryById]);

    const handleSubmit = (values) => {
        if (entryID) {
            updateEntry(entryID, values);
        } else {
            addEntry(profileId, values);
        }

        navigate(paths.petProfile(profileId));
    };

    return ({ entryID } ?
        <DiaryEntryForm key={entry.id} petId={profileId} initialValues={entry} onSubmit={handleSubmit} /> :
        (<CircularProgress />)
    );
};

export default DiaryEntryCreate;