class ApiClient {
    constructor() {
        this.baseURL = process.env.REACT_APP_BACKEND_URL;
        this.version = 'api/v1';
    }

    async get(endpoint) {
        if (!this.baseURL) throw new Error('Base URL not set');

        const response = await fetch(`${this.baseURL}/${this.version}/${endpoint}`, {
            method: 'GET',
        });
        return this.handleResponse(response);
    }

    async post(endpoint, data) {
        if (!this.baseURL) throw new Error('Base URL not set');

        const response = await fetch(`${this.baseURL}/${this.version}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        return this.handleResponse(response);
    }

    async put(endpoint, data) {
        if (!this.baseURL) throw new Error('Base URL not set');

        const response = await fetch(`${this.baseURL}/${this.version}/${endpoint}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        return this.handleResponse(response);
    }

    async delete(endpoint) {
        if (!this.baseURL) throw new Error('Base URL not set');

        const response = await fetch(`${this.baseURL}/${this.version}/${endpoint}`, {
            method: 'DELETE',
        });
        return this.handleResponse(response);
    }

    async handleResponse(response) {
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Something went wrong');
        }
        return response.json();
    }
}

export default ApiClient;